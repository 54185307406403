// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()


require("bootstrap")
require("@fortawesome/fontawesome-free/js/all")


import $ from 'jquery';
window.$ = jQuery;


//
//
// FANCYBOX
import { fancybox } from "components/fancybox";


$(function () {
  // TOOLTIP
  $('[data-toggle="tooltip"]').tooltip()
  //
  // POPOVER
  $('[data-toggle="popover"]').popover({ placement: 'left', html: true, trigger: 'focus' })

  // MENU MOBILE
  $('#navbar_main ul li.dropdown > a').click(function(e) {
    if ($(window).width() <= 991.98){
      if ($(this).next('ul').is(':hidden') ) {
        $('#navbar_main ul li.dropdown ul').hide();
        $(this).next('ul').show();
      } else {
        $(this).next('ul').hide();
      }

      e.preventDefault();
    }
  });
})
